import { graphql, useStaticQuery } from 'gatsby'

interface MenuItemRaw {
  group: string
  slug: string
  name: string
  order: number
}

interface Data {
  allContentfulService: {
    nodes: {
      group: string
      serviceName: string
      serviceId: string
      order: number
    }[]
  }
  allMdx: {
    nodes: {
      fields: {
        slug: string
      }
      frontmatter: {
        group: string
        title: string
        order: number
      }
    }[]
  }
}

export function useGetServicesNavItems(isSorted: boolean = false) {
  const data = useStaticQuery<Data>(graphql`
    query headerMenuQuery {
      allContentfulService(filter: { enabled: { eq: true }, moreButtonEnabled: { eq: true } }, sort: [{ order: ASC }]) {
        nodes {
          group
          serviceName
          serviceId
          order
        }
      }
      allMdx(
        filter: { frontmatter: { pagetype: { eq: "service" } } }
        sort: { fields: [frontmatter___order], order: ASC }
      ) {
        nodes {
          id
          fields {
            slug
          }
          frontmatter {
            title
            group
            order
          }
        }
      }
    }
  `)

  const nodesContentful: MenuItemRaw[] = data.allContentfulService.nodes.map(
    ({ group, serviceName, serviceId, order }) => ({
      group,
      name: serviceName,
      slug: `/services/${serviceId}/`,
      order,
    }),
  )

  const nodesMdx: MenuItemRaw[] = data.allMdx.nodes.map(({ fields, frontmatter }) => ({
    group: frontmatter.group,
    slug: fields.slug,
    name: frontmatter.title,
    order: frontmatter.order,
  }))

  const allServices = [...nodesContentful, ...nodesMdx]

  return !isSorted ? allServices : allServices.sort((a, b) => a.order - b.order)
}
