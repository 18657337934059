import { MenuGroup, MenuSectionsMap } from '../types'
import { useGetServicesNavItems } from '@/hooks'

export function useGetMenuItems(): MenuSectionsMap {
  const allServiceItemsList = useGetServicesNavItems()
  const serviceGroupNames = Array.from(new Set(allServiceItemsList.map(({ group }) => group)))

  const serviceGroups: MenuGroup[] = serviceGroupNames.map(group => {
    return {
      groupName: group,
      groupItems: allServiceItemsList
        .filter(item => item.group === group)
        .slice()
        .sort((a, b) => a.order - b.order)
        .map(({ name, slug }) => {
          return {
            title: name,
            url: slug,
          }
        }),
    }
  })

  const insightGroups: MenuGroup[] = [
    {
      groupItems: [
        {
          title: 'Articles and Guides',
          url: '/blog/',
        },
        {
          title: 'Videos',
          url: '/video/',
        },
        {
          title: 'Podcast',
          url: '/podcast/',
        },
      ],
    },
  ]

  return {
    Services: serviceGroups,
    Insights: insightGroups,
  }
}
