import React from 'react'
import { graphql, Link } from 'gatsby'
import { styled } from '@linaria/react'
import SiteMapSection from '../components/sitemapsection'
import { slugify } from '@/utils/slugify'
import { LightHero, Title } from '../components/hero'
import { tabletMedia } from '@/lib/theme'
import { SEO, Layout } from '@/blocks/layout'
import { useGetServicesNavItems } from '@/hooks'
import { SingleLinkContent } from '@/blocks/layout/partials/footer/components/AllLinksWrapper'
import { title } from '@linaria/babel-preset/types/process'

const pageTitle = `Sitemap`
const pageDescription =
  'Links to all pages, articles, videos, tags and on SAMexpert about Microsoft Cloud, Microsoft SPLA, Microsoft licensing and Software Asset Management'

const staticPages = [
  { title: 'Contact us', slug: 'contact' },
  { title: 'Our team', slug: 'team' },
  { title: 'All videos', slug: 'video' },
  { title: 'All articles', slug: 'blog' },
]

const SiteMapPage = ({ data }) => {
  const dynamicPages = data.allContentfulPage.nodes
  const allPages = [...staticPages, ...dynamicPages]
  const servicesRaw = useGetServicesNavItems(true)
  const allServices = servicesRaw.map(({ name, slug }) => ({
    slug,
    title: name,
  }))
  const allBlogs = data.allContentfulPost.nodes
  const allVideos = data.selectedYoutubeVideos.videos
    .filter(video => video !== null)
    .map(video => ({
      //    slug: slugify([video.videoId, video.title]),
      slug: slugify(video.title),
      title: video.title,
    }))
  const allContentfulPodcastEpisode = data.allContentfulPodcastEpisode.nodes.map(episode => ({
    slug: slugify(episode.title),
    title: episode.title,
  }))

  return (
    <Layout hasNoVerticalMargins>
      <div
        style={{
          display: 'grid',
          gridTemplate: "'hero' 'sitemap'",
          gridTemplateColumns: '100%',
        }}
      >
        <LightHero style={{ gridArea: 'hero' }}>
          <Title>{pageTitle}</Title>
        </LightHero>
        <Wrapper>
          <SiteMapSection title="Services" pages={allServices} slugIsFinalUrl />
          <SiteMapSection title="Pages" pages={allPages} />
          <SiteMapSection title="Articles" pages={allBlogs} />
          <SiteMapSection title="Videos" pages={allVideos} prefix="video" />
          <SiteMapSection title="Podcast" pages={allContentfulPodcastEpisode} prefix="podcast" />
        </Wrapper>
      </div>
    </Layout>
  )
}

export default SiteMapPage

export const Head = () => {
  return <SEO title={pageTitle} description={pageDescription} />
}

export const pageQuery = graphql`
  query SiteMapQuery {
    allContentfulPage {
      nodes {
        slug
        title
      }
    }
    allContentfulPost(sort: { datetime: DESC }) {
      nodes {
        slug
        title
      }
    }
    allContentfulService(filter: { enabled: { eq: true }, moreButtonEnabled: { eq: true } }, sort: { order: ASC }) {
      nodes {
        slug: serviceId
        title: serviceName
      }
    }
    selectedYoutubeVideos: youtubePlaylist(originalID: { eq: "PLrzGKxp1pvT_Q0UDDbfARhaFf-Cam7ZOS" }) {
      id
      videos {
        title
        videoId
      }
    }
    allContentfulPodcastEpisode(sort: { pubDate: DESC }) {
      nodes {
        title
      }
    }
  }
`

const Wrapper = styled.article`
  padding: 1em;
  grid-area: sitemap;
  justify-self: center;
  ${tabletMedia} {
    padding: 2em;
  }
`
