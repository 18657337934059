import React from 'react'
import { styled } from '@linaria/react'

import { HeaderMenuItem } from './HeaderMenuItem'
import { HeaderMenuItemDataType } from './types'
import { colors, desktopSmallMedia, fontLeagueSpartan } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'

interface Props {
  title?: string
  data: HeaderMenuItemDataType[]
  onClick?: () => void
}

export const HeaderMenuSection = ({ title, data, onClick }: Props) => {
  return (
    <HeaderMenuSectionView>
      {Boolean(title) && <HeaderMenuSectionTitle>{title}</HeaderMenuSectionTitle>}
      <ItemsWrapper>
        {data.map((item, idx) => (
          <HeaderMenuItem
            key={`${item.objectID ?? item.title}__${idx}`}
            data={item as unknown as HeaderMenuItemDataType}
            onClick={onClick}
          />
        ))}
      </ItemsWrapper>
    </HeaderMenuSectionView>
  )
}

const HeaderMenuSectionView = styled.div`
  display: grid;
  font-family: ${fontLeagueSpartan};
  grid-row-gap: 24px;

  ${desktopSmallMedia} {
    grid-row-gap: 32px;
  }
`

const HeaderMenuSectionTitle = styled.span`
  font-size: ${pxToRem(20)};
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 0.02em;
  color: ${colors.tertiary.tertiary};
  text-transform: uppercase;
  cursor: pointer;
`

const ItemsWrapper = styled.ul`
  display: grid;
`
