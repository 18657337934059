import React from 'react'
import { Link } from 'gatsby'
import { AllLinksWrapper, SingleLinkContent } from './AllLinksWrapper'
import { useGetServicesNavItems } from '@/hooks'

export const OurServices = () => {
  const allServiceItemsList = useGetServicesNavItems(true)

  return (
    <AllLinksWrapper gridArea="services">
      <h2>Services</h2>
      {allServiceItemsList.map(({ name, slug }, index) => (
        <Link key={index} to={slug}>
          <SingleLinkContent>{name}</SingleLinkContent>
        </Link>
      ))}
    </AllLinksWrapper>
  )
}
