import React, { useEffect } from 'react'
import { useSnapshot } from 'valtio'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'
import { MacScrollbar } from 'mac-scrollbar'
import { desktopSmallMedia } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'
import { PortalWrapper, Wrapper } from '@/UI'
import { HeaderSectionsItemsWrapper, HeaderMenuSection } from '@/blocks/layout/partials/header/components'
import { RootMenuItemsDesktop } from './RootMenuItemsDesktop'
import { menuSectionsToDisplay } from '../presets'
import { useGetMenuItems } from '../hooks'
import { removeActiveNavSection, navSectionsState, navSectionsMouseOverState } from '../state'
import { OpacityCommon } from './styles'

export const NavigationDesktop = () => {
  const menuSections = useGetMenuItems()
  const { activeSection } = useSnapshot(navSectionsState)
  const sectionUnderMouse = useSnapshot(navSectionsMouseOverState)

  useEffect(() => {
    if (!sectionUnderMouse.size) removeActiveNavSection()
  }, [sectionUnderMouse.size])

  return (
    <NavigationDesktopView>
      <RootMenuItemsDesktop menuSections={menuSectionsToDisplay} />
      <PortalWrapper
        closeHandler={removeActiveNavSection}
        isVisible={Boolean(activeSection)}
        offsetTop="var(--header-h)"
        renderInDOM
        role="navigation"
      >
        <WrapperStyled>
          <RelativeWrapper
            onMouseEnter={() => navSectionsMouseOverState.add('sections')}
            onMouseLeave={() => navSectionsMouseOverState.delete('sections')}
          >
            {menuSectionsToDisplay.map((menuSection, idx) => (
              <NavSectionView key={`${menuSection}__${idx}`} $isVisible={activeSection === menuSection}>
                <MacScrollbar
                  skin="dark"
                  className={css`
                    width: 100%;
                    padding-bottom: ${pxToRem(72)};
                  `}
                >
                  <HeaderSectionsItemsWrapper as="nav">
                    {menuSections[menuSection].map(({ groupName, groupItems }, idx) => (
                      <HeaderMenuSection
                        key={`${groupName}-${idx}`}
                        title={groupName}
                        data={groupItems}
                        onClick={removeActiveNavSection}
                      />
                    ))}
                  </HeaderSectionsItemsWrapper>
                </MacScrollbar>
              </NavSectionView>
            ))}
          </RelativeWrapper>
        </WrapperStyled>
      </PortalWrapper>
    </NavigationDesktopView>
  )
}

const NavigationDesktopView = styled.div`
  display: none;
  height: 100%;
  margin-right: 3rem;

  ${desktopSmallMedia} {
    display: flex;
    align-items: center;
  }
`

const WrapperStyled = styled(Wrapper)`
  padding: ${pxToRem(0, 40)};
  max-height: 100%;
  flex-grow: 1;
`

const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: ${pxToRem(72, 32)};
  
`

const NavSectionView = styled(OpacityCommon)`
  display: flex;
  width: calc(100% - ${pxToRem(64)});
  height: calc(100% - ${pxToRem(72)});
  position: absolute;
`
