import React, { useCallback } from 'react'
import { Link } from 'gatsby'
import { styled } from '@linaria/react'
import { useLocation } from '@reach/router'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import BookAnAppointment from './bookanappointment'
import theme from '~/gatsby-plugin-theme-ui'
import { doNotPrintThisBlock } from '@/lib/sharedStyles'

const expireTime = 30

export const Extras = () => {
  const location = useLocation()

  const acceptHandler = useCallback(() => {
    Cookies.set('gatsby-gdpr-google-analytics', 'true', { expires: expireTime })
    Cookies.set('gatsby-gdpr-google-tagmanager', 'true', { expires: expireTime })
    initializeAndTrack(location)
  }, [location])

  return (
    <ExtrasView>
      <div style={{ gridArea: 'extras' }}>
        <BookAnAppointment />
        <SlideUp>
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            enableDeclineButton
            declineButtonText="Decline"
            onAccept={acceptHandler}
            expires={expireTime}
            style={{
              background: theme.colors.dark,
            }}
            flipButtons
          >
            <p>
              We would like to use analytical cookies to help us improve our website. We will not do that without your
              explicit consent. Please visit our{' '}
              <Link style={{ color: theme.colors.light }} to="/cookies/">
                cookies policy
              </Link>{' '}
              page to learn more.
            </p>
          </CookieConsent>
        </SlideUp>
      </div>
    </ExtrasView>
  )
}

const ExtrasView = styled.div`
  display: flex;
  ${doNotPrintThisBlock};
`

const SlideUp = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  animation: slide 1s 5s forwards;
  transform: translateY(110vh);

  @keyframes slide {
    from {
      transform: translateY(110vh);
    }
    to {
      transform: translateY(0vh);
    }
  }
`
