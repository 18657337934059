import React from 'react'
import { styled } from '@linaria/react'
import { useSnapshot } from 'valtio'
import { pxToRem } from '@/utils/unitsInRem'
import { colors, media } from '@/lib/theme'
import { setActiveNavSection, navSectionsState, navSectionsMouseOverState } from '../state'

interface Props {
  menuSections: string[]
}

export const RootMenuItemsDesktop = ({ menuSections }: Props) => {
  const { activeSection } = useSnapshot(navSectionsState)

  return (
    <RootMenuItemsDesktopView
      onMouseEnter={() => navSectionsMouseOverState.add('root')}
      onMouseLeave={() => navSectionsMouseOverState.delete('root')}
    >
      {menuSections.map(sectionName => (
        <TouchableMenuItem
          key={sectionName}
          $isActive={activeSection === sectionName}
          onMouseEnter={() => {
            setActiveNavSection(sectionName)
          }}
        >
          {sectionName}
        </TouchableMenuItem>
      ))}
    </RootMenuItemsDesktopView>
  )
}

const RootMenuItemsDesktopView = styled.div`
  display: none;
  
  ${media.desktopSmall} {
    height: 100%;
    display: grid;
    grid-auto-flow: column;
  }
`
const TouchableMenuItem = styled.button<{ $isActive: boolean }>`
  font-size: ${pxToRem(18)};
  line-height: 1;
  height: 100%;
  padding: ${pxToRem(4, 24, 0, 24)};
  display: flex;
  align-items: center;
  position: relative;
  color: ${colors.white};
  cursor: pointer;
  //pointer-events: ${({ $isActive }) => ($isActive ? 'none' : 'all')};

  &:after {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: 2rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    border-radius: 1rem;
    background-color: ${colors.tertiary.darkest};
    transition: opacity 0.3s ease-in-out;
    opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
    bottom: calc(50% - 14px);
  }
`
